import React, { Component } from "react";
import Slider from "react-slick";
import _ from "lodash";
import Img from "gatsby-image";
import { Link } from "gatsby";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "../../sass/blocks/collection-list.scss";

export default class CollectionsListSlider extends Component {
  renderItems(collections) {
    return _.map(collections, collection => {
      const {
        title,
        date,
        path,
        img: {
          childImageSharp: { fixed }
        }
      } = collection.node.frontmatter;
      return (
        <div>
          <div className="container">
            <Link to={path}>
              <Img fixed={fixed} />
            </Link>
            <div className="title">{title}</div>
            <small>{date}</small>
          </div>
        </div>
      );
    });
  }
  render() {
    const settings = {
      //autoplay: true,
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
      <div className="collection-list">
        <Slider {...settings}>
          {this.renderItems(this.props.collections)}
        </Slider>
      </div>
    );
  }
}
