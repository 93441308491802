import React from "react";
import { Parallax } from "react-parallax";
import "../../sass/blocks/parallax-collections.scss";

const ParallaxArtistar = props => (
	<div className="parallax-collection mobile-only">
		<Parallax
			bgImage={props.img.src}
			bgImageAlt="Artistar 2019 - Millan, Italy"
			strength={500}
		>
			<div className="excerpt">
				<h3>Artistar Jewels 2019 - Exhibition</h3>
				<a href="/collection/solar-storm" className="btn">
					See More
				</a>
			</div>
			<div style={{ height: "500px" }} />
		</Parallax>
	</div>
);

export default ParallaxArtistar;
