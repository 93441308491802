import React from 'react';
import '../../sass/blocks/intro-excerpt-txt.scss';

const introExcerptTxt = props => (
	<div className="intro-excerpt-txt txt-wrapper">
		<h2>Fiorella Pattoni</h2>
		<div className="content">
			<p>A strong sense of empowerment with subtle silent sophistication.</p>
			<p>
				All pieces are created in limited series or as unique pieces, all of
				them one of a kind treasures. Seductive elegance in each art piece that
				enhance oneself.
			</p>
		</div>
	</div>
);

export default introExcerptTxt;
