import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Link } from "gatsby";

import '../../sass/blocks/about-txt.scss';

const aboutTxt = ({ img }) => (
	<div className="about-txt txt-wrapper">
		<div>
			<Img fluid={img} />
		</div>
		<div>
			<p>
				<strong className="emphasis">Fiorella Pattoni</strong> is an architect,
				designer and a jewelry artist. She is passionate about multidisciplinary
				projects . In her unique style she combines, design, fashion and art in
				finely crafted handmade pieces. She believes that ornaments are a
				statement of who we are, of where we came from, with whom we are
				connected to. They are symbols that both express and communicates.
			</p>
			<Link to='/about-me' title='Fiorella Pattoni - Jewelry'>Read more</Link>
		</div>
	</div>
);

export default aboutTxt;
