import React from "react";
import SEO from "../components/seo";
import MediaQuery from "react-responsive";

import ParallaxIntroExcerpt from "../components/frontpage/parallaxIntroExcerpt";
import ParallaxArtistar from "../components/frontpage/parallaxArtistar";
import IntroExcerptTxt from "../components/frontpage/introExcerptTxt";
import AboutTxt from "../components/frontpage/aboutTxt";
import CollectionsList from "../components/frontpage/collectionsListSlider";

const IndexPage = ({ data }) => (
  <div>
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
    <ParallaxIntroExcerpt img={data.parallaxIntro.childImageSharp.fluid} />
    <IntroExcerptTxt />
    <CollectionsList collections={data.collections.edges} />
    <ParallaxArtistar img={data.parallaxArtistar.childImageSharp.fluid} />
    <AboutTxt img={data.aboutImg.childImageSharp.fluid} />
  </div>
);

export const aboutTxtquery = graphql`
  query {
    aboutImg: file(relativePath: { eq: "about.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 200, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    parallaxIntro: file(relativePath: { eq: "parallax/front-bg1.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1778) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    parallaxArtistar: file(relativePath: { eq: "parallax/front-bg2.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1778) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    collections: allMarkdownRemark(
      filter: { frontmatter: { path: { regex: "/collection/" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            date
            path
            img {
              childImageSharp {
                fixed(width: 200) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
