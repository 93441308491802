import React from 'react';
import { Parallax } from 'react-parallax';
import '../../sass/blocks/parallax-intro-excerpt.scss'

const parallaxIntroExcerpt = props => (
	<div className="parallax-intro-excerpt mobile-only">
		<Parallax
			bgImage={props.img.src}
			bgImageAlt="Fiorella Pattoni - Handmade wearable art"
			strength={500}
		>
			<div className="excerpt">
				<p>Finely handmade wearable art.</p>
				<p>Belong to the exceptional.</p>
			</div>
			<div style={{ height: '500px' }} />
		</Parallax>
	</div>
);

export default parallaxIntroExcerpt;
